<template>
	<div >
		<el-dialog title="群聊" :visible.sync="visiable" width="360px" custom-class="group">
			<div class="select-content" v-if="Object.keys(data).length > 0">
				<div class="group-item">
					<li>
						<p>群头像</p>
						<p><img :src="photoUrl + data.group.is_photo"></p>
					</li>
					<li v-if="data.is_action == 1 || data.is_action == 2" @click="handleGroupNick()">
						<p>群昵称</p>
						<p><span>{{data.group.name}}</span><i></i></p>
					</li>
					<li v-else>
						<p>群昵称</p>
						<p><span>{{data.group.name}}</span></p>
					</li>
					<li @click="handleCopy(data.group.id)">
						<p>群ID（点击复制）</p>
						<p><span>{{data.group.id}}</span></p>
					</li>
					<li @click="handleGroupMyNick()">
						<p>我在本群昵称</p>
						<p><span>{{data.my_nickname}}</span><i></i></p>
					</li>
				</div>
				<div class="group-item-space"></div>
				<div class="group-item">
					
					<li v-if="data.is_action == 1 || data.is_action == 2" @click="handleGroupNotice()">
						<p>群公告</p>
						<p><span>{{data.group.notice}}</span><i></i></p>
					</li>
					<li v-else>
						<p>群公告</p>
						<p><span>{{data.group.notice}}</span></p>
					</li>
					<li @click="handleGroupManager()"v-if="data.is_action == 1 || data.is_action == 2">
						<p>群管理</p>
						<p><span></span><i></i></p>
					</li>

				</div>
				<div class="group-item-space"></div>
				<div class="group-item">
					<li>
						<p>置顶聊天</p>
						<p>
			
							<el-switch v-model="data.top ==1" inactive-color="#dcdfe6" active-color="#007AFF" @change="handleSetting(1, !data.top)"></el-switch>
						</p>
					</li>
					<li>
						<p>消息免打扰</p>
						<p>
							<el-switch v-model="data.is_disturb ==1"  inactive-color="#dcdfe6" active-color="#007AFF" @change="handleSetting(2, !data.is_disturb)"></el-switch>
						</p>
					</li>

				</div>
			</div>
			<span slot="footer" class="dialog-footer">

			</span>
		</el-dialog>
		<groupManager ref="groupManager"></groupManager>
		<groupNick ref="groupNick" ></groupNick>
		<groupMyNick ref="groupMyNick"  ></groupMyNick>
		<groupNotice ref="groupNotice"></groupNotice>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { mapState, mapActions, mapMutations } from 'vuex'
	import VueClipboard from 'vue-clipboard2'
	Vue.use(VueClipboard)
	import { Message } from 'element-ui';
	import { getChatDetails, msgDisturb, chatTop, deleteChat } from '@/api'
	
	import groupManager from '@/components/group/groupManager.vue'
	import groupNick from '@/components/group/groupNick.vue'
	import groupMyNick from '@/components/group/groupMyNick.vue'
	import groupNotice from '@/components/group/groupNotice.vue'
	const { photoUrl } = window.__gconf
	export default {
		components: {
			groupManager,
			groupNick,
			groupMyNick,
			groupNotice
		},
		data() {
			return {
				data:{},
				visiable: false,
				photoUrl: photoUrl
			}
		},
		computed: {
		  ...mapState([
		    'selectId',
			'groupDetail'
		  ]),
		},
		created() {
			this.data = this.groupDetail;
		},
		methods: {
			...mapMutations(['clearSelectId']),
			...mapActions([
			'fetchCharList'
			]),
			handleGroupManager() {
				this.$refs.groupManager.open()
			},
			handleGroupNick() {
				this.$refs.groupNick.open()
			},
			handleGroupMyNick() {
				this.$refs.groupMyNick.open()
			},
		
			handleGroupNotice() {
				this.$refs.groupNotice.open()
			},
			
			handleCopy(text){
				this.$copyText(text).then( (e) =>{
					Message({
					   showClose: true,
					   message: "复制成功",
					   type: 'success',
					   duration: 1000
					})
				}, (e) =>{
					Message({
					   showClose: true,
					   message: "复制失败",
					   type: 'error',
					   duration: 1000
					})
				})
				this.$copyText(text);
			},
			handleSetting (type, value) {
			  const data = {
			    list_id: this.selectId,
			    value: value ? '1' : '0'
			  }
			  let fetchUrl
			  if (type == 1) {
			    fetchUrl = chatTop
				this.data.top = value ? 1 : 0;
			  } else if (type == 2) {
			    fetchUrl = msgDisturb
				this.data.is_disturb = value ? 1 : 0;
			  } else {
			    fetchUrl = deleteChat
			  }
			  console.log(value);
			  fetchUrl(data).then(res => {
				
				//置顶时刷新数据
				this.fetchCharList('')

				if (type == 3) {
			      this.clearSelectId()
			    }
			  })
			},
			open() {
				console.log(this.groupDetail)
				console.log(Object.keys(this.groupDetail).length)
				
				if(Object.keys(this.groupDetail).length !== 0){
					
					this.visiable = true;
					this.data = this.groupDetail;
					
				} else {
					getChatDetails({
					  list_id: this.selectId
					}).then(res => {
					  this.data = res.data
					 this.visiable = true;
					})
				}
				
				
	
			}
		}
	}
</script>
<style lang="less">
	.group {
		.el-dialog__body {
			padding: 0 0;
		}
	}
</style>
<style lang="less" scoped>
	.select-content {
		max-height: 380px;
		overflow-y: scroll;
	}

	.group-item-space {
		width: 100%;
		background-color: #eee;
		height: 10px;
	}

	.group-item {
		width: 100%;
		padding: 0 9px 0 15px;
		box-sizing: border-box;
		
		li {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 44px;
			cursor: pointer;
			p {
				color: #303133;
				display: flex;
				align-items: center;

				span {
					color: #999;
					max-width: 200px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					
				}

				img {
					width: 40px;
					height: 40px;
					border-radius: 5px;
				}

				i {
					color: #999;
					margin-left: 4px;
					background: url(../../assets/images/jt.png);
					background-size: 100%;
					width: 20px;
					height: 20px;
				}
			}
		}
	}
</style>

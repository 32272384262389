<template>
    <div class="coord-picker">
      <div class="map-container">
        <amap
          cache-key="coord-picker-map"
          mmap-style="amap://styles/whitesmoke"
          async
          :center.sync="center"
          :zoom.sync="zoom"
          is-hotspot
        >
          <amap-marker v-if="position" :position.sync="position" draggable />
        </amap>
      </div>
    </div>
  </template>
   
  <script>
  export default {
    props: {
      timeStamp: {
        type: Number,
      },
      mapData: {
        type: String,
      },
    },
    data() {
      return {
        center: null,
        zoom: 10,
        position: null,
      };
    },
    watch: {
      timeStamp() {
        this.center = [115.479646, 35.234309];
        this.zoom = 10;
        this.position = null;
        this.initMap();
      },
      mapData() {
        this.initMap();
      },
    },
    created() {
      this.initMap();
    },
    methods: {
      // 搜索输入地区信息
      async initMap() {
        if (!this.mapData) {
          return;
        }
        let mapPoi = this.mapData.split(",");
        if (mapPoi.length < 2) {
          this.center = [115.479646, 35.234309];
          this.zoom = 10;
          return;
        }
        this.center = mapPoi;
        this.zoom = 14;
        this.position = mapPoi;
      },
    },
  };
  </script>
   
  <style>
  .map-container {
    width: 100%;
    height: 500px;
  }
  </style>